<template>
	<div class="vx-row panel_invoice">
		<div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
			<vx-card>
				<h2>{{ type }} N°{{ number }}:</h2>
				<vs-divider/>

				<div class="vx-row mb-6">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Date</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{date}} à {{heure}}</span>
					</div>
				</div>

				<div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Membre</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewMember(uid_member)">
						<span>{{member}}</span>
					</div>
				</div>

				<div class="vx-row mb-6">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Vendeur</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewMember(uid_vendor)">
						<span>{{vendor}}</span>
					</div>
				</div>

				<div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Terminal</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewTerminal(uid_terminal)">
						<span>{{terminal}}</span>
					</div>
				</div>

				<!-- DIFFERE -->
				<div v-if="creance!=0 && type=='Facture'" class="list_recu">
					<vs-divider></vs-divider>
					<vs-collapse>
						<vs-collapse-item>
							<div class="vx-row mb-6" slot="header">
								<div class="vx-col sm:w-2/3 w-full">
									<span>Créance initial</span>
								</div>
								<div class="vx-col sm:w-1/3 w-full">
									<span>{{ creance.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
								</div>
							</div>
							<div v-for="(recu, compte) in recu_list" :key="recu.uid" >
								<div class="vx-row mb-6" >
									<div class="vx-col sm:w-2/3 w-full cursor-pointer" v-on:click="viewFacture(recu.uid)">
										{{ compte+1 }}. <span class="text-primary"> Reçu N° {{ recu.number }}</span>
									</div>
									<div class="vx-col sm:w-1/3 w-full">
										<span>{{ recu.pay_dif.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</span>
									</div>
								</div>
							</div>
						</vs-collapse-item>
					</vs-collapse>
					<div class="vx-row mb-6" >
						<div class="vx-col sm:w-2/3 w-full">
							<b>Créance restante</b>
						</div>
						<div class="vx-col sm:w-1/3 w-full">
							<b>{{ creance_non_paye.toFixed(devise.nb_decimal) }} {{ devise.symbol }}</b>
						</div>
					</div>

				</div>
				<!-- RECU -->
				<div v-if="type=='Reçu'">
					<vs-divider></vs-divider>
					<div class="vx-row mb-6" >
						<div class="vx-col sm:w-1/3 w-full">
							<span>Facture ref.</span>
						</div>
						<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewFacture(recu_list[0].fac_uid)">
							<span>{{ getFactureRef(recu_list[0].fac_name) }}</span>
						</div>
					</div>
				</div>

			</vx-card>
			<br/>

			<div v-for="item in item_credit" :key="item.uid" >
			<vx-card class="card_item" v-bind:title="item.name">
				<div class="vx-row mb-6" v-if="item.machine">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Groupe</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{ StrCapital(item.machine_name) }}</span>
					</div>
				</div>

				<div class="vx-row mb-6" v-if="item.credit!=0">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Séance(s)</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{ getSeance(item) }} Utilisées</span>
					</div>
				</div>

				<div class="vx-row mb-6" v-if="item.expire!=0">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Fin le</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{ getDateExpire(item.expire, item.date) }}</span>
					</div>
				</div>

				<div class="vx-row mb-6" v-if="item.expire!=0">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Statut</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<b>{{ getStatus(item.status) }}</b>
					</div>
				</div>

				<div class="vx-row mb-6" v-if="item.favoir">
					<div class="vx-col sm:w-1/3 w-full text-danger" >
						<span v-if="item.type=='fa'">Avoir ref.</span>
						<span v-if="item.type=='av'">Facture ref.</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewFacture(item.favoir)">
						<b>{{ item.fname }}</b>
					</div>
				</div>
			</vx-card>
			</div>

		</div>
		<div class="vx-col w-full sm:w-1/2 lg:w-2/3 mb-base">
			<iframe  v-bind:src=" $srvApi.buildUrl('user/caddy/') + $route.params.uid" class="w-full" height="700" frameBorder="0"></iframe>
		</div>
	</div>
</template>



<style lang="scss">
.panel_invoice
{
	iframe{
		background-color:#dedede;
	}
	.card_item{
		margin-bottom:20px;
	}

	.list_recu{
		.vs-collapse{
			padding:0px !important;
		}
		header{
			padding:0px !important;
			font-size:1em;
		}
	}
}
</style>

<script>
import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';
import Credit from '@/assets/utils/credit';

import Member from '@/database/models/member';
import Invoice from '@/database/models/invoice';
import Terminal from '@/database/models/terminal';
import Machine from '@/database/models/machine';

export default {
    data(){
        return{
					number   : 0,
					type     : '?',
					member   : '?',
					date     : '?',
					heure    : '?',
					vendor   : '?',
					terminal : '?',
					creance  : 0,
					creance_non_paye:0,

					recu_list:[],
					item_credit:[],

					uid_member   : '',
					uid_vendor   : '',
					uid_terminal : '',
					devise: {}
        }
	},
	mounted:function()
	{
		this.$srvApi.Req('get', 'public/currency')
		.then(( rep )=>{
				this.devise = rep.data;
		})
		load(this);
	},
	watch:{
        $route (){
            load(this);
        }
    },
    methods:{
		getDateExpire:function(expire,date)
		{
			if(expire)
				return Fdate.getDate( Invoice.getDateFin(expire,date) )+' (inclu)';
			return 'Aucune';
		},
		StrCapital:Utils.StrCapital,
		getSeance:function( item )
		{
			let msg = item.used+' / ';
			if(item.credit == -1 )
				msg += 'Illimité';
			else
				msg+=item.credit;
			return msg;
		},
		getStatus:function( status )
		{
			if(status)
				return 'Actif';
			return 'Expiré';
		},
		getFactureRef:function( uid_facture )
		{
			if( uid_facture )
				return uid_facture;
			return 'Aucune référence !';
		},
		viewMember:function( uid )
		{
			if(uid)
			this.$router.push('/admin/member/'+uid);
		},
		viewTerminal:function( uid )
		{
			if(uid)
			this.$router.push('/admin/terminal/'+uid);
		},
		viewFacture:function( uid )
		{
			if(uid)
			this.$router.push('/admin/invoice/'+uid);
		}
    }
};


function load(root)
{
	//get facture
	Invoice.getMemory( root.$route.params.uid, async function(fac)
	{
		root.number  = fac.number;
		root.date    = Fdate.getDate(fac.createAt);
		root.heure   = Fdate.getHeure(fac.createAt);
		root.type    = Invoice.getTypeName(fac);
		root.creance = fac.pay_dif;
		root.creance_non_paye = 0;
		root.recu_list = [];
		root.item_credit = [];


		//get member
		if(fac.member)
		Member.getMemory( fac.member, function(mbr)
		{
			root.member = Utils.StrCapital( mbr.first_name+" "+mbr.last_name );
			root.uid_member = mbr.uid;
		});

		//get vendor
		if(fac.vendor)
		Member.getMemory( fac.vendor, function(mbr)
		{
			root.vendor = Utils.StrCapital( mbr.first_name+" "+mbr.last_name);
			root.uid_vendor = mbr.uid;
		});

		//get terminal
		if(fac.terminal)
		Terminal.getMemory( fac.terminal, function(terminal)
		{
			root.terminal = terminal.mac;
			root.uid_terminal = terminal.uid;
		});


		//Facture calcule des recu
		if( fac.pay_dif>0)
		getRecuSignFac( fac.member, function( list_recu)
		{
			//console.log( list_recu );

			let non_paye = 0;
			let tab_recu = [];

			//uniquement recu de cette facture
			for( var i=0; i<list_recu.length; i++)
			if(list_recu[i].fac_uid == fac.uid )
			{
				tab_recu.push( list_recu[i] );
				non_paye += list_recu[i].pay_dif;
			}

			root.recu_list = tab_recu;
			root.creance_non_paye = fac.pay_dif+non_paye;
		});

		//Recu calcule lien facture
		if( fac.type=='re')
		getRecuSignFac( fac.member, function( list_recu )
		{
			//uniquement le recu en lecture
			for( var i=0; i<list_recu.length; i++)
			if(list_recu[i].uid == fac.uid )
				root.recu_list.push(list_recu[i]);
		});


		//Info item
		let list_item = await Credit.getItemParse( fac.member );

		//utilisation des credit item
		let item_credit = await Credit.itemCreditUse( fac.member );
		let item_credit_use = item_credit.credit;

		var tab_final = [];
		for( const i in list_item )
		{
			if(list_item[i].caddy == fac.uid )//uniquement la facture en cours
			{
				var buffer = list_item[i];
				buffer.used = 0;
				buffer.status = Invoice.getActif( buffer.expire, buffer.date );

				if(item_credit_use[buffer.uid])
					buffer.used = item_credit_use[buffer.uid];
				tab_final.push(buffer);
			}
		}

		//triage alphanumerique
		tab_final.sort(function(a,b)
		{
			if(a.name < b.name )
				return -1;
			if ( a.name > b.name )
				return 1;
		});

		//Add name machine
		Machine.datafor(tab_final,function( data,end)
		{
			data.machine_name = null;
			if(data.machine)
			Machine.getMemory( data.machine,function(rep)
			{
				data.machine_name = rep.name;
				end();
			});
			else
				end();
		})
		.then(function()
		{
			root.item_credit = tab_final;
		});
	});
}



//----------------------------------------------------------------
//List des recu et facture tagé
function getRecuSignFac( uid_member, callback )
{
  //get facture
  Invoice.getTabAllMemoryKey( uid_member, function( list_facture )
  {
      //Triage par date
      list_facture.sort(function(a, b)
      {
          let a_date = (new Date(a.createAt)).getTime();
          let b_date = (new Date(b.createAt)).getTime();

          if(a_date> b_date)
            return 1;
          if(b_date> a_date)
           return -1;
      });

      //enlever les avoir
      let list_facture_sans_avoir = [];
      for( const i in list_facture )
      if( list_facture[i].type == 'fa' || list_facture[i].type == 're' )
        list_facture_sans_avoir.push( list_facture[i] );

      //boucle de triage
      let tab_return = [];
      let facture_memory = null;
      let facture_name = null;
      for( const p in list_facture_sans_avoir )
      {
        var buffer = list_facture_sans_avoir[p];
        //init facture memory
        if(buffer.type == 'fa' )
        if( buffer.pay_dif > 0)
        {
          facture_memory = buffer.uid;
          facture_name   = buffer.number;
        }

        //si recu
        if(buffer.type == 're' )
        {
          buffer.fac_uid  = facture_memory;
          buffer.fac_name = facture_name;
          tab_return.push( buffer );
        }
      }

      //end
      if(callback)
        callback(tab_return);
  });
}

</script>
